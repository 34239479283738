/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.grid.grid_layout_2-3_1-3.grid_reverse_mobile {
  flex-direction: column-reverse;
}
@media (min-width: 1200px) {
  .grid.grid_layout_2-3_1-3.grid_reverse_mobile {
    flex-direction: row;
  }
  .grid.grid_layout_2-3_1-3 > .grid__item:nth-child(2n-1) {
    width: 66.6666666667%;
    padding-right: 16px;
  }
  .grid.grid_layout_2-3_1-3 > .grid__item:nth-child(2n) {
    width: 33.3333333333%;
    padding-left: 16px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_2 {
    width: calc(100% + 32px);
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_2 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
  .grid.grid_cols_2 > .grid__item:last-child {
    margin-right: 0;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_5 {
    width: calc(100% + 32px);
  }
}
@media (min-width: 1200px) {
  .grid.grid_cols_5 {
    width: calc(100% + 32px / 2);
    margin-bottom: -16px;
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_5 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
}
@media (min-width: 1200px) {
  .grid.grid_cols_5 > .grid__item {
    width: calc(100% / 5 - 32px / 2);
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.section.section_type_home-search-bar {
  padding-top: 0;
  margin-top: -40px;
  background-color: transparent;
}
.section.section_type_home-search-bar .section__content {
  min-height: 330px;
}
@media (min-width: 768px) {
  .section.section_type_home-search-bar {
    padding-bottom: 70px;
  }
  .section.section_type_home-search-bar .section__content {
    min-height: 80px;
  }
}

.section.section_type_home-search-bar {
  padding-bottom: 90px;
}
.section.section_type_home-search-bar .search-bar__main-options,
.section.section_type_home-search-bar .search-bar__secondary-options {
  box-shadow: 0 20px 60px 0 rgba(9, 9, 9, 0.25);
}
.section.section_type_home-search-bar + .section_type_properties-and-areas {
  padding-top: 0;
}
@media (min-width: 768px) {
  .section.section_type_home-search-bar + .section_type_properties-and-areas {
    padding-top: 20px;
  }
}
@media (min-width: 1200px) {
  .section.section_type_home-search-bar + .section_type_properties-and-areas .section__subheading {
    margin-top: 8px;
  }
}

.section.section_type_properties .section__heading {
  font-size: 24px;
}
.section.section_type_properties .section__heading + .section__content {
  padding-top: 0;
}

.container__adaptive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.button {
  display: inline-block;
  height: auto;
  min-height: 60px;
  line-height: 60px;
  text-decoration: none;
  text-align: center;
  padding: 0 48px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  border: none;
  appearance: none;
  font-size: 16px;
  color: #fff;
  background: #706456;
}
.button:hover {
  color: #fff;
  background: #142e39;
}
.button_size_small {
  height: auto;
  min-height: 40px;
  line-height: 40px;
  padding: 0 24px;
}
.button_width_full {
  width: 100%;
}

.button_theme_transparent {
  background-color: transparent;
  color: #706456;
  border: 1px solid #706456;
}

.favorite-toggle > svg:nth-child(2) {
  display: none;
}

.favorite-toggle.favorite-toggle_state_active > svg:nth-child(1) {
  display: none;
}
.favorite-toggle.favorite-toggle_state_active > svg:nth-child(2) {
  display: inline-block;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.autocomplete {
  position: relative;
  color: #090914;
}
.autocomplete__input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 48px;
  border: 2px solid #c3c3c4;
  border-radius: 4px;
  background: #fff;
  padding: 12px 10px;
}
.autocomplete__input > input {
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  flex: 100;
  width: 100%;
  min-width: 100px;
  font-size: inherit;
}
.autocomplete__selected {
  color: #090914;
  background: #706456;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 2px 0 2px 8px;
  border-radius: 2px;
}
.autocomplete__remove {
  display: inline-block;
  cursor: pointer;
  padding: 0 8px;
}
.autocomplete__results {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.autocomplete__result {
  cursor: pointer;
  padding: 12px 10px;
  color: #090914;
}
.autocomplete__result:hover {
  color: #090914;
  background: #e6e6e6;
}
.autocomplete__result_town, .autocomplete__result_type_town {
  font-weight: bold;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.checkbox-list {
  position: relative;
  color: #090914;
}
.checkbox-list__text {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 10px;
  border-radius: 3px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.checkbox-list__text:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23090914"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.checkbox-list__count {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  margin-left: 4px;
  color: #fff;
  background: #706456;
}
.checkbox-list__options {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.checkbox-list__option {
  position: relative;
  cursor: pointer;
  padding: 12px 10px;
}
.checkbox-list__box {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid #c3c3c4;
  background: #fff;
  border-radius: 2px;
}
.checkbox-list__option.checkbox-list__option_group {
  font-weight: bold;
  text-transform: uppercase;
  cursor: initial;
}
.checkbox-list__option.checkbox-list__option_group > .checkbox-list__box {
  display: none;
}
.checkbox-list__option.checkbox-list__option_checked > .checkbox-list__box {
  border-color: #706456;
  background: #706456;
}
.checkbox-list_opened > .checkbox-list__options {
  display: block;
}
.checkbox-list_opened > .checkbox-list__text:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23090914"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}

.dropdown {
  position: relative;
  color: #090914;
}
.dropdown__value {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 40px 7px 12px;
  border-radius: 4px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.dropdown__value:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23090914"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.dropdown__options {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.dropdown__option {
  cursor: pointer;
  padding: 12px;
  color: #090914;
}
.dropdown__option:hover {
  color: #090914;
  background: #e6e6e6;
}
.dropdown__option.dropdown__option_state_selected {
  color: #fff;
  background: #706456;
}
.dropdown_state_opened > .dropdown__value:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23090914"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}
.dropdown_state_closed > .dropdown__options {
  display: none;
}
.dropdown_state_opened > .dropdown__options {
  display: block;
}

.switcher {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  color: #090914;
}
.switcher__control {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 48px;
  height: 28px;
  border-radius: 16px;
  background-color: #efeeef;
  transition: background-color 0.3s ease;
}
.switcher__control:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s ease, left 0.3s ease;
}
.switcher__label {
  display: inline-block;
  margin-left: 12px;
}
.switcher_state_checked > .switcher__control {
  background-color: #706456;
}
.switcher_state_checked > .switcher__control:after {
  left: 24px;
  background-color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.hero__heading {
  position: relative;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  max-width: 540px;
  margin: 0;
  padding: 0;
  font-size: 40px;
}
.hero > .section__background-image.image > img {
  position: absolute;
  object-position: center;
}
@media (min-width: 768px) {
  .hero__heading {
    max-width: 100%;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.hero {
  text-align: center;
}
.hero > .section__background-image.image > img {
  object-position: center;
}
.hero .hero__heading {
  color: #fff;
  font-size: 48px;
}
.hero .hero__subheading {
  max-width: 640px;
  margin: 24px auto 0;
  padding: 12px;
  background-color: rgba(39, 61, 79, 0.75);
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: normal;
  border-radius: 2px;
}
@media (min-width: 768px) {
  .hero {
    padding: 140px 0;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.search-bar {
  position: relative;
}
.search-bar__container {
  width: 280px;
  margin: 0 auto;
}
.search-bar__main-options {
  position: relative;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
}
.search-bar__main-options .dropdown__value,
.search-bar__main-options .checkbox-list__text,
.search-bar__main-options .autocomplete__input {
  background-color: transparent;
  border: none;
  padding: 0;
}
.search-bar__main-options .autocomplete__input {
  min-height: 32px;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
  border-bottom: 1px solid #ececec;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list,
.search-bar__main-options .autocomplete {
  padding: 32px 0 16px;
}
.search-bar__main-options .dropdown:after,
.search-bar__main-options .checkbox-list:after,
.search-bar__main-options .autocomplete:after {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
  color: #090914;
}
.search-bar__main-options .dropdown:after {
  content: "STATUS";
}
.search-bar__main-options .checkbox-list:after {
  content: "TYPE";
}
.search-bar__main-options .autocomplete:after {
  content: "LOCATION";
}
.search-bar__secondary-options {
  display: none;
  margin-top: 64px;
  padding: 16px 16px 0;
  background-color: #fff;
  border-radius: 3px;
  border-top: 4px solid #706456;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .checkbox-list__text {
  border: none;
  background-color: #efefef;
  border-radius: 2px;
  min-height: 24px;
  line-height: 24px;
}
.search-bar__secondary-options .switcher {
  display: flex;
  margin-top: 16px;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .dropdown__options,
.search-bar__secondary-options .checkbox-list__text,
.search-bar__secondary-options .checkbox-list__options,
.search-bar__secondary-options .switcher {
  font-size: 14px;
}
.search-bar__secondary-options .dropdown + .dropdown,
.search-bar__secondary-options .dropdown + .checkbox-list {
  margin-top: 12px;
}
.search-bar__secondary-options_state_open {
  display: block;
}
.search-bar__options {
  position: absolute;
  bottom: -32px;
  right: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #090914;
  cursor: pointer;
  border-bottom: 1px dotted #090914;
}
.search-bar__options:hover {
  color: #090914;
  border-color: #090914;
}
.search-bar__submit {
  padding: 16px 0;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #706456;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.search-bar__submit:hover {
  color: #fff;
  background-color: #534a40;
}
.search-bar .clearfix {
  display: block;
  width: 100%;
}
@media (min-width: 375px) {
  .search-bar__container {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .search-bar__container {
    width: 350px;
  }
}
@media (min-width: 768px) {
  .search-bar__container {
    width: 720px;
  }
  .search-bar__main-options {
    display: flex;
    padding: 0 0 0 16px;
  }
  .search-bar__main-options .dropdown {
    flex-basis: 160px;
    margin-right: 16px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 180px;
    margin-right: 16px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 240px;
  }
  .search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
    border-bottom: none;
  }
  .search-bar__main-options .dropdown:before,
.search-bar__main-options .checkbox-list:before {
    content: "";
    position: absolute;
    top: 24px;
    right: 0;
    width: 1px;
    height: 32px;
    border-right: 1px solid #ececec;
  }
  .search-bar__submit {
    flex-grow: 1;
    padding-top: 28px;
    border-radius: 0 3px 3px 0;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(50% - 8px);
    flex-shrink: 1;
    flex-grow: 0;
    margin-bottom: 16px;
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 0;
  }
  .search-bar__secondary-options_state_open .dropdown + .dropdown,
.search-bar__secondary-options_state_open .dropdown + .checkbox-list {
    margin-top: 0;
  }
  .search-bar__secondary-options_state_open .switcher {
    margin-top: 0;
    margin-bottom: 16px;
    margin-right: 32px;
  }
}
@media (min-width: 1024px) {
  .search-bar__container {
    width: 920px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 225px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 355px;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(25% - 12px);
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(4n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(4n) {
    margin-right: 0;
  }
  .search-bar__options {
    right: 22px;
  }
}

@media (min-width: 1200px) {
  .search-bar__container {
    width: 1096px;
  }
  .search-bar__main-options .dropdown {
    flex-basis: 160px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 240px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 480px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.area-group + .area-group {
  margin-top: 8px;
}
.area-group__toggle {
  display: none;
}
.area-group__suburbs {
  margin: 12px 0 24px;
  display: none;
}
.area-group__toggle:checked ~ .area-group__suburbs {
  display: block;
}
.area-group__town {
  position: relative;
  font-weight: bold;
}
.area-group__town > .link:after {
  position: relative;
  top: 2px;
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23090914"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
}
.area-group__toggle:checked + .area-group__town > .link:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23090914"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}
.area-group__suburb {
  display: flex;
  font-size: 14px;
}
.area-group__suburb:before {
  content: "";
  display: inline;
  position: relative;
  top: 8px;
  width: 4px;
  height: 4px;
  margin-right: 8px;
  background-color: currentColor;
}
.area-group__suburb + .area-group__suburb {
  margin-top: 12px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.advantage {
  color: #090914;
  text-align: center;
  text-decoration: none;
}
.advantage__icon > svg {
  fill: #706456;
  color: #706456;
  max-width: 100%;
  max-height: 100%;
}
.advantage__heading {
  font-weight: bold;
  margin-bottom: 8px;
}
.advantage__description {
  font-size: 14px;
  line-height: 24px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.advantages-grid {
  justify-content: center;
}

.advantage_type_grid .advantage__icon {
  width: 56px;
  height: 56px;
  margin: 0 auto 16px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card {
  display: flex;
  color: #090914;
  flex-direction: column;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  overflow: hidden;
  font-size: 16px;
}
.property-card__visuals {
  position: relative;
}
.property-card__details {
  padding: 16px;
}
.property-card__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 6px 0;
}
.property-card__tag {
  padding: 0 8px;
  font-size: 12px;
  color: #fff;
  background: #555;
  border-radius: 2px;
  text-align: center;
  height: 24px;
  line-height: 24px;
}
.property-card__favorite {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  color: #fff;
  background: #706456;
  cursor: pointer;
}
.property-card__favorite > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  fill: currentColor;
}
.property-card__favorite:hover {
  color: #fff;
  background: #534a40;
}
.property-card__area {
  position: absolute;
  width: 100%;
  padding: 16px 8px 8px;
  bottom: 0;
  left: 0;
  color: #fff;
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%);
  font-size: 12px;
}
.property-card__area:before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="%23FFFFFF"><path d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"/></svg>');
  width: 12px;
  height: 10px;
}
.property-card__heading {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 4px;
}
.property-card__price {
  font-size: 20px;
  color: #002a50;
  margin-bottom: 8px;
}
.property-card__features {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px dashed #d0d0d0;
  margin-top: 8px;
}
.property-card__feature {
  display: flex;
  flex: 0 0 auto;
  line-height: 40px;
  margin-right: 16px;
}
.property-card__feature:last-child {
  margin-right: 0;
}
.property-card__feature-icon {
  margin-right: 8px;
}
.property-card__feature-icon > svg {
  width: 20px;
  vertical-align: middle;
  fill: #090914;
}
.property-card__feature_type_baths > .property-card__feature-icon > svg {
  position: relative;
}
.property-card__feature_type_building > .property-card__feature-icon > svg {
  position: relative;
}
.property-card__description {
  font-size: 14px;
  margin-top: 8px;
}
@media (min-width: 768px) {
  .property-card {
    flex-direction: row;
  }
  .property-card__visuals {
    display: flex;
  }
  .property-card__image {
    padding-bottom: 0;
  }
  .property-card__visuals, .property-card__image, .property-card__photos {
    flex: 0 0 290px;
  }
  .property-card__features {
    display: inline-flex;
  }
}

.property-card__address {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  font-style: italic;
}
.property-card__address-icon {
  width: 16px;
  margin-right: 4px;
}

.property-card__address_type_country svg {
  position: relative;
  top: 1px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card__photos {
  position: relative;
}
.property-card__photo {
  position: relative;
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.property-card__photo > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.property-card__control {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
}
.property-card__control > svg {
  width: 10px;
  fill: #fff;
  filter: drop-shadow(0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.property-card__control:hover > svg {
  fill: #090914;
}
.property-card__control_type_prev {
  left: 0;
}
.property-card__control_type_prev > svg {
  left: 8px;
}
.property-card__control_type_next {
  right: 0;
}
.property-card__control_type_next > svg {
  right: 8px;
}

.property-card__photo {
  height: 100%;
  padding-bottom: 0;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
#why-story.section .advantages-grid {
  margin-top: 24px;
}

#about-story.section .section__content {
  padding: 24px;
  background-color: #706456;
  color: #fff;
  max-width: 540px;
  overflow: hidden;
  border-radius: 4px;
}
#about-story.section .section__heading {
  color: #fff;
}
@media (min-width: 768px) {
  #about-story.section .section__content {
    padding: 64px;
  }
}